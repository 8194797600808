import React from 'react';

function TestPage(props) {
  const {pageContext} = props;

  return (
    <div>
      <h2>Page Context</h2>
      <p>{JSON.stringify(pageContext, null, 2)}</p>
    </div>
  );
}

export default TestPage;
